import { createSharedComposable } from '@vueuse/core'
import * as Sentry from '@sentry/nuxt'
import type { ColorLabelValue, DateRange, LabelValue, TableColumn, TableColumnSort } from '~/types/common/table'
import api from '~/api'
import type {
  BookingDrivers,
  BookingResult,
  BookingTableMetadata,
  BookingTableQueryFilters,
  BookingTableRow,
  BookingTableTabsFilters
} from '~/types/booking'
import { buildBookingFiltersQuery, buildColumnsWithSavedSettings } from '~/utils/helpers/TableHelper'
import {
  BOOKING_DEFAULT_FILTER, BOOKING_FETCH_INTERVAL, BookingTabsEnum
} from '~/constants/bookings'
import { constructRegulateDate } from '~/utils/helpers/DateHelper'

const _useBookingTable = (): any => {
  const dataTable = ref<BookingTableRow[]>([])
  const columnsTable = ref<TableColumn[]>([])
  const columns = ref<TableColumn[]>([])
  const tabsFilters = ref<BookingTableTabsFilters[]>([])
  const currentTab = ref<BookingTabsEnum[]>([])
  const initialData = ref([])
  const isFetching = ref(true)

  const drivers = ref<BookingDrivers[]>([])
  const selectedDrivers = ref<BookingDrivers[]>([])
  const validationStatus = ref<ColorLabelValue[]>([])
  const selectedValidationStatus = ref<ColorLabelValue[]>([])
  const bookingStatus = ref<LabelValue[]>([])
  const selectedBookingStatus = ref<LabelValue[]>([])
  const searchTerms = ref('')
  const sort = ref<TableColumnSort>({ column: '', direction: '' as const })
  const selectedDateRange = ref<DateRange>({ start: null, end: null })

  const currentPageCount = ref(BOOKING_DEFAULT_FILTER.page)
  const totalPages = ref(0)
  const totalElements = ref(0)
  const rowPerPage = ref(BOOKING_DEFAULT_FILTER.size)
  const pageFrom = computed(() => (currentPageCount.value - 1) * rowPerPage.value)
  const pageTo = computed(() => Math.min(currentPageCount.value * rowPerPage.value, totalElements.value))

  const fetchDataTableInterval = ref(null)
  const fetchTabFiltersInterval = ref(null)

  const refresh = ref<Date>(Date())

  const saveTableColumnsSettings = (columns: TableColumn[]): void => {
    localStorage.setItem('columnSettings', JSON.stringify(columns))
  }

  const fetchTableTabsFilters = async (isInitFetching?: boolean): Promise<void> => {
    try {
      const { data, error } = await useAPI(api.getBookingTabsFilters())
      if (error.value) throw error.value

      const res: BookingTableTabsFilters[] = data.value
      tabsFilters.value = res
      if (isInitFetching) {
        console.log('SET CURRENT TAB', res[0]?.filterValue)
        currentTab.value = res[0]?.filterValue
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const fetchTableMetadata = async (): Promise<void> => {
    try {
      const { data, error } = await useAPI(api.getBookingTableMetadata())
      if (error.value) throw error.value

      const res: BookingTableMetadata = data.value
      columns.value = buildColumnsWithSavedSettings(res.columns)
      drivers.value = res.availableDrivers
      validationStatus.value = res.availableValidationStatus
      bookingStatus.value = res.availableBookingStatus
      // Set initial filter
      selectedValidationStatus.value = res?.availableValidationStatus?.filter(elem => BOOKING_DEFAULT_FILTER.validationStatuses.includes(elem.value))
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const fetchTableData = async ({
    terms,
    dateRange,
    newDrivers,
    validationStatuses,
    newSort,
    bookingStatuses,
    pageNumber,
    pageSize
  }): Promise<void> => {
    try {
      if (!isFetching.value) isFetching.value = true

      const filtersQuery: BookingTableQueryFilters = buildBookingFiltersQuery(terms, dateRange, newDrivers, validationStatuses, newSort, bookingStatuses, pageNumber - 1, pageSize, columns.value)

      const { data, error } = await useAPI(api.getBookingFiltered(filtersQuery))
      if (error.value) throw error.value

      const res: BookingResult = data.value
      initialData.value = res.content
      totalPages.value = res.totalPages
      totalElements.value = res.totalElements
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      isFetching.value = false
    }
  }

  const initTableDataFetching = async (): Promise<void> => {
    try {
      await fetchTableTabsFilters(true)
      await fetchTableMetadata()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  watch(columns, (newValue) => {
    if (newValue?.length && !columnsTable.value.length) {
      columnsTable.value = newValue.filter((column: any) => column.visible)
    }
  })

  watch(initialData, (newValue) => {
    dataTable.value = newValue
  })

  const updateColumnSettings = (selectedColumns: any): void => {
    columnsTable.value = selectedColumns.value.filter((column: any) => column.visible)
    columns.value = selectedColumns.value
  }

  const resetFilters = (): void => {
    searchTerms.value = ''
    selectedValidationStatus.value = []
    selectedBookingStatus.value = []
    selectedDrivers.value = []
    selectedDateRange.value = { start: null, end: null }
  }

  watchDebounced(
    [searchTerms, selectedDateRange, selectedDrivers, selectedValidationStatus, sort, selectedBookingStatus, currentPageCount, rowPerPage, refresh],
    ([
      terms,
      dateRange,
      newDrivers,
      validationStatuses,
      newSort,
      bookingStatuses,
      pageNumber,
      pageSize]) => {
      if (import.meta.client) {
        stopFetchingDataTableInterval()
        fetchTableData({
          terms,
          dateRange,
          newDrivers,
          validationStatuses: validationStatuses.map(elem => elem.value),
          newSort,
          bookingStatuses: bookingStatuses.map(elem => elem.value),
          pageNumber,
          pageSize
        })
        if (currentTab.value.includes(BookingTabsEnum.PENDING)) {
          startFetchingDataTableInterval()
        }
      }
    },
    { debounce: 200, maxWait: 1000 }
  )

  const onStatusTabsChange = (selectedTabIndex: number): void => {
    const selectedTab: BookingTableTabsFilters = tabsFilters.value[selectedTabIndex]
    currentTab.value = selectedTab.filterValue
    currentPageCount.value = BOOKING_DEFAULT_FILTER.page

    if (selectedTab.filterKey === 'validationStatuses') {
      selectedBookingStatus.value = []
      selectedValidationStatus.value = validationStatus.value.filter(elem =>
        selectedTab.filterValue.includes(elem.value)
      )
    } else if (selectedTab.filterKey === 'bookingStatuses') {
      selectedValidationStatus.value = []
      selectedBookingStatus.value = bookingStatus.value.filter(elem =>
        selectedTab.filterValue.includes(elem.value)
      )
    } else {
      selectedBookingStatus.value = []
      selectedValidationStatus.value = []
    }
  }

  watch(currentTab, (newVal) => {
    if (newVal && newVal.includes(BookingTabsEnum.PENDING)) {
      startFetchingDataTableInterval()
    } else stopFetchingDataTableInterval()
  })

  const refreshTable = (): void => {
    refresh.value = Date()
    fetchTableTabsFilters()
  }

  const cancelBooking = async (bookingId: number): Promise<void> => {
    try {
      const { error } = await useAPI(api.rejectBooking(bookingId))
      if (error.value) throw error.value

      refreshTable()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const regulateBooking = async (bookingId: number, departureDate: string, selectedHour: number, selectedTime: number): Promise<void> => {
    try {
      const departureDateTime = constructRegulateDate(departureDate, selectedHour, selectedTime)
      const { error } = await useAPI(api.regulateBooking({ departureDateTime, bookingId }))
      if (error.value) throw error.value

      refreshTable()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const assignDriverBooking = async (bookingId: number, vehicleId: string): Promise<void> => {
    try {
      const { error } = await useAPI(api.assignDriverBooking({ vehicleId, bookingId }))
      if (error.value) throw error.value

      refreshTable()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const startFetchingTabFiltersInterval = (): void => {
    if (!fetchTabFiltersInterval.value) {
      fetchTabFiltersInterval.value = setInterval(() => {
        fetchTableTabsFilters()
      }, BOOKING_FETCH_INTERVAL)
    }
  }

  const stopFetchingTabFiltersInterval = (): void => {
    if (fetchTabFiltersInterval.value) {
      clearInterval(fetchTabFiltersInterval.value)
      fetchTabFiltersInterval.value = null
    }
  }

  const startFetchingDataTableInterval = (): void => {
    if (!fetchDataTableInterval.value) {
      fetchDataTableInterval.value = setInterval(() => {
        fetchTableData({
          newSort: sort.value,
          pageNumber: currentPageCount.value,
          pageSize: rowPerPage,
          validationStatuses: selectedValidationStatus.value.map(elem => elem.value),
          newDrivers: selectedDrivers.value,
          bookingStatuses: selectedBookingStatus.value.map(elem => elem.value),
          terms: searchTerms.value,
          dateRange: selectedDateRange.value
        })
      }, BOOKING_FETCH_INTERVAL)
    }
  }

  const stopFetchingDataTableInterval = (): void => {
    if (fetchDataTableInterval.value) {
      clearInterval(fetchDataTableInterval.value)
      fetchDataTableInterval.value = null
    }
  }

  const resetPagination = (): void => {
    currentPageCount.value = BOOKING_DEFAULT_FILTER.page
  }

  onMounted(() => {
    startFetchingTabFiltersInterval()
  })

  onUnmounted(() => {
    stopFetchingDataTableInterval()
    stopFetchingTabFiltersInterval()
  })

  return {
    initTableDataFetching,
    saveTableColumnsSettings,
    tabsFilters,
    isFetching,
    columns,
    dataTable,
    drivers,
    bookingStatus,
    selectedBookingStatus,
    validationStatus,
    updateColumnSettings,
    onStatusTabsChange,
    cancelBooking,
    regulateBooking,
    assignDriverBooking,
    columnsTable,
    searchTerms,
    selectedDrivers,
    selectedValidationStatus,
    resetFilters,
    selectedDateRange,
    currentPageCount,
    totalPages,
    totalElements,
    rowPerPage,
    pageFrom,
    pageTo,
    sort,
    resetPagination
  }
}

export const useBookingTable = createSharedComposable(_useBookingTable)
